import React from 'react';

import Layout from '../components/layout';
import Faq from '../components/faq';
import Pricing from '../components/pricing';
import SEO from '../components/seo';
import About from '../components/about';

const Index = () => {
    return (
        <Layout footerColor="bg-white">
            <SEO />

            <section className="h-screen flex justify-center text-center items-center bg-gray-50 mx-auto">
                <div className="px-2 sm:px-0">
                    <h3 className="text-sm lg:text-lg my-4">Pour les personnes et organisations responsables 🌱</h3>

                    <h2 className="mx-auto text-3xl max-w-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">Gérez vos données de manière éthique</h2>

                    <h3 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Profitez des avantages de l'auto-hébergement tout en évitant les problèmes techniques</h3>

                    <div className="mt-4">
                        <a href="/app/fr/register" className="button inline-block bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded">Essayer gratuitement</a>
                        <p className="text-sm mt-2">
                            <span role="img" aria-label="lock">🔒</span>{' '}
                            <b>Gratuit pendant 7 jours et sans engagement</b>
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-12 bg-white">
                <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
                    <div className="lg:text-center">
                        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                            Économisez de l'argent 💸
                        </h3>

                        <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                            Ethibox remplace un tas d'applications propriétaires. Découvrez comment notre prix se compare aux autres offres.
                        </p>
                    </div>

                    <div className="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-12 lg:grid-flow-col-dense mt-12">
                        <div className="col-span-5">
                            <div className="bg-gray-100 rounded-md">
                                <div className="p-4 flex items-center">
                                    <img src="/logo.svg" alt="Zapier" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Ethibox</p>
                                        <p>99€/mois pour 8 applications open-source</p>
                                    </div>
                                </div>
                                <ul className="px-4 pb-4">
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Utilisateurs illimités</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Données hébergées en France</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Nom de domaine personnalisé</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Sans tracking</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Open-source</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Écoresponsable</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Sauvegardes quotidiennes</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Installation en 1 clic</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">Mises à jour automatiques</span>
                                    </li>
                                    <li className="flex my-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2 font-bold">99% de disponibilité</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                </svg>
                                <div className="text-center">
                                    <p className="font-bold">99€/mois, montant fixe.</p>
                                    <p>Grandissez autant  que vous voulez, toujours 99€/mois au total.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center col-span-1 !my-4">
                            <span className="bg-gray-100 p-4 font-bold rounded-full">VS</span>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 flex flex-col gap-3 relative col-span-6">
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/O9xQ71p.png" alt="Dropbox" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Dropbox : Stockage</p>
                                        <p>15€/mois par utilisateur</p>
                                    </div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/jzaO6B7.png" alt="Slack" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Slack : Tchat</p>
                                        <p>7€50/mois par utilisateur</p>
                                    </div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10 hidden md:block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/rOTFBFd.png" alt="Zapier" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Zapier : Automatisation</p>
                                        <p>26.92€/mois par utilisateur</p>
                                    </div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/uVaRTYO.png" alt="Zendesk" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Zendesk : Help Desk</p>
                                        <p>49€/mois par utilisateur</p>
                                    </div>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/bSlgoso.png" alt="Hubspot" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Hubspot : Marketing</p>
                                        <p>46€/mois pour 2 utilisateurs</p>
                                    </div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/xfbqyxR.png" alt="Shopify" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Shopify : Ecommerce</p>
                                        <p>29€/mois pour 2 utilisateurs</p>
                                    </div>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/wZySP6U.jpg" alt="Trello" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Trello : Gestion de projet</p>
                                        <p>6€/mois par utilisateur</p>
                                    </div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 rounded-md mx-auto -my-4 z-10 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                                <div className="bg-gray-100 w-full rounded-md p-4 flex items-center">
                                    <img src="https://i.imgur.com/jmii4O7.png" alt="Mailchimp" className="w-12" />
                                    <div className="ml-2">
                                        <p className="font-bold">Mailchimp : Newsletter</p>
                                        <p>9€/mois pour 500 contacts</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                </svg>
                                <div className="text-center">
                                    <p className="font-bold">188.42€/mois pour 1 utilisateur</p>
                                    <p>472.26€/mois pour 3 utilisateurs, 1803.2€ pour 10…</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white pt-16 pb-32 overflow-hidden">
                <div className="mt-24">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div>
                                <div>
                                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Installez vos applications en 1 clic 🚀</h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Ethibox travaille pour vous et vous laisse libre de vous concentrer sur l'essentiel de vos applications. Obtenez un accès à plus de +30 applications open-source à installer en 1 clic.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 flex items-center">
                            <div className="px-4 lg:px-0">
                                <img className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 w-auto" src="/screenshot1.png" alt="Store d'applications" style={{ boxShadow: '0 0px 12px rgb(0 0 0 / 20%)' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-24">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                            <div>
                                <div>
                                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Gérez vos applications sans connaissances techniques 😌</h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Évitez l'enfer technique, ne perdez plus votre temps avec les spécificités techniques (CPU, RAM, SSL, CDN, DNS, SSH, FTP, Bande passante, Sécurité, Sauvegardes, Performances, etc...) et consacrez-vous uniquement à l'utilisation de vos applications.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 flex items-center">
                            <div className="px-4 lg:px-0">
                                <img className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 w-auto" src="/screenshot2.png" alt="Gérez vos applications sans connaissances techniques" style={{ boxShadow: '0 0px 12px rgb(0 0 0 / 20%)' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-24">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div>
                                <div>
                                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Contribuez à l'élimination du dioxyde de carbone 🌱</h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Pour contrer le réchauffement climatique, il est essentiel d'éliminer le CO₂ de l'atmosphère. 1 % de votre abonnement contribue à développer les nouvelles technologies d'élimination du CO₂. Tous les serveurs d'Ethibox utilisent de l'électricité issue à 100% d'énergies renouvelables.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 flex items-center">
                            <div className="px-4 lg:px-0">
                                <img className="w-full" src="/screenshot3.png" alt="Store d'applications" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <About />

            <section className="py-10 bg-gray-50">
                <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-5">
                        <div className="flex justify-center items-center">
                            <img className="h-12" src="/clients/ecologie-les-verts.png" alt="Ecologie les verts" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="h-8" src="/clients/datactivist.png" alt="Datactivist" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="h-12" src="/clients/telecoop.png" alt="Telecoop" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="h-12" src="/clients/univ.png" alt="Université de lorraine" />
                        </div>
                        <div className="flex justify-center items-center">
                            <img className="h-12" src="/clients/artfx.png" alt="Artfx" />
                        </div>
                    </div>
                </div>
            </section>

            <Pricing />

            <Faq />
        </Layout>
    );
};

export default Index;
